<template>
  <div id="quiz-question">
    <div
      v-bind:class="
        'bg-' + (!!htmlInstruction.color ? htmlInstruction.color : 'light-gray')
      "
      style="width: 100vw; margin-left: -1rem; margin-right: -1rem;"
    >
      <div class="p-2 px-3 my-1 rounded bg-white d-inline-block">
        <img
          v-bind:src="forceSlash(htmlInstruction.icon)"
          style="width: 1em; height: 1em; margin-right: 1em;"
          v-if="!!htmlInstruction.color"
        />
        <img
          v-else
          src="/images/themes/ET/menu/fa-question.svg"
          style="width: 1em; height: 1em;"
        />
        <b>{{ htmlInstruction.phrase }}</b>
      </div>
    </div>
    <div v-html="question"></div>

    <transition name="bounce">
      <p id="clue" v-if="show_clue" v-html="'<b>Clue:</b> ' + clue"></p>
    </transition>

    <div id="answers">
      <div>
        <quizAnswer
          v-for="(option, id) in options"
          v-bind:key="id"
          v-bind:isCorrect="option.isCorrect"
          v-bind:animate.sync="option.animate"
          v-model="option.isKnown"
          v-on:known="score--"
          v-on:correct-found="correctFound"
        >
          {{ option.option }}
        </quizAnswer>
      </div>
    </div>

    <div v-if="help_state > 0 && !done">
      <a
        class="btn btn-secondary"
        id="help"
        v-bind:class="helpAvailable"
        v-on:click="getHelp"
      >
        Help me please ! ({{ help_left }})
      </a>
    </div>
    <transition name="falldown">
      <div v-if="done">
        <div id="description" v-html="description"></div>
        <a class="btn btn-primary" id="next" v-on:click="nextQuestion">
          Next
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import quizAnswer from "@/components/QuizAnswer.vue";

export default {
  name: "quizQuestion",
  components: {
    quizAnswer
  },
  inject: ["nextQuestion"],
  props: [
    "question",
    "description",
    "clue",
    "options",
    "htmlInstruction",
    "help_left"
  ],
  data() {
    return {
      done: false,
      help_state: 2, // 2:clue, 1:remove, 0:none
      show_clue: false,
      score: this.options.length - 1
    };
  },
  methods: {
    forceSlash: function(str) {
      if (str.charAt(0) !== "/") return "/" + str;
      else return str;
    },
    correctFound: function() {
      this.options.forEach(function(option) {
        option.isKnown = true;
        if (!option.isCorrect) {
          option.animate = false;
        }
      });
      // show next button
      this.done = true;
      // compensate for the lost points
      this.score++;

      this.$emit("score", this.score);
    },
    getHelp: function() {
      if (this.help_left <= 0) {
        // no help
        return;
      }
      if (this.help_state == 2) {
        this.show_clue = true;
      }
      if (this.help_state == 1) {
        var random_answers_indexes = this.options
          .reduce(this.getClueableAnswers, [])
          .sort(function() {
            return Math.round(Math.random()) - 0.5;
          })
          .slice(0, 2);
        random_answers_indexes.forEach(this.setKnownByIndex);
      }
      this.help_state--;
      this.$emit("ask-help");
    },
    getClueableAnswers: function(res, e, index) {
      if (!e.isCorrect && !e.isKnown) {
        return res.concat([index]);
      } else {
        return res;
      }
    },
    setKnownByIndex: function(i) {
      this.options[i].isKnown = true;
    }
  },
  computed: {
    helpAvailable: function() {
      return {
        disabled: this.help_left <= 0
      };
    }
  }
};
</script>

<style scoped lang="scss">
#quiz-question {
  @extend .px-3;
  text-align: center;
  .blank {
    min-width: 7em;
    // border-bottom: 1px dotted black;
    display: inline-block;

    position: relative;

    &:after {
      content: "";
      position: absolute;
      // bottom: -0.4rem;
      height: 0.5rem;
      left: -0.25rem;
      right: -0.25rem;
      // Position the line behind the text so that
      // it is still easily readable
      z-index: -1;

      // The SVG is added as an SVG background image
      background-image: url("/images/underline.svg");
      background-repeat: no-repeat;

      // This allows the SVG to flex in size to fit
      // any length of word. If the word is short,
      // the SVG will be stretched vertically, if it
      // is long, the SVG will be stretched horizontally.
      // The jagged nature of this particular SVG works
      // with this transforming.
      // background-size: cover;
    }
  }

  & + div:not(#quiz-progress) {
    @extend .mx-auto;
  }

  #description {
    @extend .mt-3;
    @extend .mb-1;
  }
}

#answers {
  @extend .pt-1;
  @extend .container;

  & > div {
    @extend .row;
    @extend .border;
    @extend .rounded;
    overflow: hidden;
  }

  /* t-learning */
  a {
    @extend .col-sm-12;
  }

  /* quiz */
  button {
    @extend .col-sm-6;

    @include media-breakpoint-up(sm) {
      &:nth-last-child(2) {
        border: 0 !important;
      }

      &:nth-child(odd) {
        border-right: $border-width solid $border-color !important;
      }
    }
  }

  button,
  a {
    @extend .py-3;
    @extend .btn;
    @extend .border-bottom;
    @extend .rounded-0;

    &:last-child {
      border: 0 !important;
    }

    &.success {
      @extend .bg-primary;
      @extend .text-light;
      &:after {
        content: " ✔";
      }
    }
    &.success.animated {
      animation: bounce2-in 0.5s;
    }

    &.fail {
      @extend .text-danger;
      @extend .disabled;
      &:after {
        content: " ✘";
      }
    }
    &.fail.animated {
      animation: shake 0.5s;
    }
  }
}
</style>
