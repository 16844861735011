<template>
  <button
    type="button"
    v-on:click.stop.prevent.once="setKnown"
    v-bind:class="checkAnswer"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "QuizAnswer",
  model: {
    prop: "isKnown",
    event: "known"
  },
  props: {
    isCorrect: {
      type: Boolean,
      default: false
    },
    isKnown: {
      type: Boolean,
      default: false
    },
    animate: {
      type: Boolean
      // default: false
    }
  },
  methods: {
    setKnown() {
      if (this.animate !== false) {
        this.$emit("update:animate", true);
      }
      this.$emit("known", true);
      if (this.isCorrect) {
        this.$emit("correct-found");
      }
    }
  },
  computed: {
    checkAnswer() {
      return {
        animated: this.isKnown && this.animate,
        fail: this.isKnown && !this.isCorrect,
        success: this.isKnown && this.isCorrect
      };
    }
  }
};
</script>
