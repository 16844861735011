<template>
  <div class="quiz" v-if="!started">
    <portal to="topbar-text">
      <h1>Quiz</h1>
    </portal>

    <Loading class="mx-auto d-block mt-5" style="width: 5em;" v-if="!quiz" />
    <div class="text-center" v-else>
      <h3>{{ quiz.title }}</h3>
      <p>{{ quiz.description }}</p>
      <button class="btn btn-primary" v-on:click="startQuiz">Let's go!</button>
    </div>
  </div>
  <div class="quiz" v-else>
    <portal to="topbar-menu" v-bind:disabled="onResultsPage"
      ><div></div
    ></portal>
    <portal to="topbar-text">
      <h2>Quiz</h2>
      <p>{{ quiz.title }}</p>
    </portal>

    <p v-if="debug">current question: {{ current_question }}</p>

    <div class="text-center" v-if="show_results && !onResultsPage">
      <button
        class="btn btn-primary"
        id="ok"
        v-on:click.stop.prevent="gotoResults"
      >
        Back to the results
      </button>
    </div>

    <transition v-bind:name="transition_name" mode="out-in">
      <keep-alive>
        <quizQuestion
          v-bind:key="current_question"
          v-bind="quiz.questions[current_question]"
          v-bind:help_left="help_left"
          v-on:ask-help="help_left--"
          v-on:score="updateScore(current_question, $event)"
          v-if="!onResultsPage"
        />
      </keep-alive>
    </transition>

    <transition name="fade" mode="out-in">
      <portal to="footer-head" v-bind:disabled="onResultsPage">
        <transition name="fade" mode="out-in">
          <div id="quiz-progress" v-bind:class="quizProgressResults">
            <ul>
              <li
                v-for="(q, i) in quiz.questions"
                v-bind:key="i"
                v-bind:class="progressValue(i)"
                v-on:click="gotoQuestionIfPossible(i)"
                style="max-width: 50vh;"
              >
                <span v-if="show_results || i < current_question">
                  {{ getCorrectAnswer(q) }}
                </span>
                <small v-if="onResultsPage"
                  >({{
                    formatTime(times[i] - (i == 0 ? 0 : times[i - 1]))
                  }})</small
                >
              </li>
            </ul>
          </div>
        </transition>
      </portal>
    </transition>

    <portal to="topbar-img" v-bind:disabled="onResultsPage">
      <div v-if="!show_results">
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#pausemodal"
          v-on:click="stopChrono($event)"
        >
          <!-- <p>05:23</p> -->
          <svg
            v-if="!this.chrono.running"
            width="1em"
            height="1em"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1576 927l-1328 738q-23 13-39.5 3t-16.5-36v-1472q0-26 16.5-36t39.5 3l1328 738q23 13 23 31t-23 31z"
              fill="#fff"
            />
          </svg>
          <svg
            v-else
            width="1em"
            height="1em"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1664 192v1408q0 26-19 45t-45 19h-512q-26 0-45-19t-19-45v-1408q0-26 19-45t45-19h512q26 0 45 19t19 45zm-896 0v1408q0 26-19 45t-45 19h-512q-26 0-45-19t-19-45v-1408q0-26 19-45t45-19h512q26 0 45 19t19 45z"
              fill="#fff"
            />
          </svg>
        </button>
        <p style="font-size: medium;">
          {{ chrono.time }}
        </p>
      </div>
    </portal>

    <div
      class="modal fade"
      id="pausemodal"
      data-backdrop="static"
      data-keyboard="false"
      role="dialog"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered text-center">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Quiz paused</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
             <span aria-hidden="true">&times;</span>
             </button> -->
          </div>
          <div class="modal-body">
            <br />
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              v-on:click="startChrono"
            >
              Continue
            </button>
            <br /><br />
            <router-link
              class="btn btn-secondary"
              to="/solo"
              data-dismiss="modal"
            >
              Go back to the menu
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center" v-if="onResultsPage">
      <button class="btn btn-secondary" v-on:click="reset">
        Play again!
      </button>
      <br /><br />
      <router-link class="btn btn-primary" to="/solo">
        Go back to the menu
      </router-link>
    </div>

    <div v-if="debug">
      <a class="btn btn-primary" v-on:click="gotoQuestionRelative(-1)">
        Prev
      </a>
      <a class="btn btn-primary" v-on:click="gotoQuestionRelative(1)">
        Next
      </a>
      <a class="btn btn-primary" v-on:click="gotoResults">
        End
      </a>
      <span>{{ scores }}</span>
    </div>
  </div>
</template>

<script>
import quizQuestion from "@/components/QuizQuestion.vue";
import Loading from "@/components/Loading.vue";
import api from "@/services/api.js";

const MAX_HELP = 4;

export default {
  name: "quiz",
  components: {
    quizQuestion,
    Loading
  },

  mounted: function() {
    this.$store.dispatch("fillQuizList").then(() => {
      this.startNewQuiz();
    });
  },

  data: function() {
    return {
      debug: false,
      started: false,
      transition_name: "diapo-next",
      current_question: 0,
      show_results: false,
      scores: null,
      times: null,
      help_left: MAX_HELP,
      quiz: null,
      chrono: {
        start: null,
        stop: null,
        stoppedDuration: 0,
        running: false,
        time: "0:00",
        interval: null
      }
    };
  },

  watch: {
    current_question(newQ /* , oldQ */) {
      // do not go upper than n questions + 1 result panel
      if (!!this.quiz && newQ > this.quiz.questions.length) {
        this.current_question = this.quiz.questions.length;
      }
      // do not go below 0
      if (newQ < 0) {
        this.current_question = 0;
      }
      // when on result pannel, the quiz is solved
      if (!!this.quiz && newQ == this.quiz.questions.length) {
        this.show_results = true;
      }
    }
  },

  methods: {
    reset: function() {
      clearInterval(this.chrono.interval);
      Object.assign(this.$data, this.$options.data.apply(this));
      this.startNewQuiz();
    },
    startNewQuiz: function() {
      let id = null;
      if (this.$route.params.id != null) {
        id = this.$route.params.id;
      } else {
        // Random
        let n = Math.floor(Math.random() * this.$store.state.quizList.length);
        let url_array = this.$store.state.quizList[n]["@id"].split("/");
        id = url_array[url_array.length - 1];
      }
      api.getQuiz(id).then(res => {
        this.quiz = res;
        this.scores = new Array(this.quiz.questions.length).fill(-1);
        this.times = new Array(this.quiz.questions.length).fill(-1);
        this.quiz.questions.forEach(this.shuffleAnswers);
      });
    },
    startQuiz: function() {
      this.started = true;
      this.startChrono();
    },
    updateScore: function(question, event) {
      this.stopChrono();
      this.scores[question] = event;
      this.times[question] = this.getElapsedTimeMs();
    },
    formatTime: function(time) {
      let ms = ("00" + (Math.floor(time / 10) % 100)).slice(-2);
      let seconds = Math.floor(time / 1000);
      return seconds + ":" + ms;
    },
    startChrono: function() {
      if (this.chrono.running) return;
      if (this.chrono.start === null) this.chrono.start = new Date();
      if (this.chrono.stop !== null)
        this.chrono.stoppedDuration += new Date() - this.chrono.stop;
      this.chrono.interval = setInterval(() => {
        if (!this.started) return "0:00";
        else {
          this.chrono.time = this.formatTime(this.getElapsedTimeMs());
        }
      }, 73);
      this.chrono.running = true;
    },
    stopChrono: function(fromPauseButton) {
      if (fromPauseButton && !this.chrono.running) {
        fromPauseButton.stopPropagation();
        return;
      }
      this.chrono.running = false;
      this.chrono.stop = new Date();
      clearInterval(this.chrono.interval);
    },
    getElapsedTimeMs() {
      return new Date(
        new Date() - this.chrono.start - this.chrono.stoppedDuration
      ).getTime();
    },
    shuffleAnswers: function(question) {
      question.options.sort(function() {
        return 0.5 - Math.random();
      });
    },
    gotoQuestionRelative: function(i) {
      this.transition_name = i < 0 ? "diapo-prev" : "diapo-next";
      this.current_question += i;
      window.scrollTo(0, 0);
    },
    gotoQuestion: function(i) {
      this.transition_name =
        this.current_question > i ? "diapo-prev" : "diapo-next";
      this.current_question = i;
      window.scrollTo(0, 0);
    },
    nextQuestion: function() {
      this.gotoQuestionRelative(1);
      setTimeout(this.startChrono, 500);
    },
    gotoResults: function() {
      this.gotoQuestion(this.quiz.questions.length);
    },
    gotoQuestionIfPossible: function(i) {
      if (this.show_results) {
        this.gotoQuestion(i);
      }
    },
    getCorrectAnswer: function(question) {
      for (var a of question.options) {
        if (a.isCorrect) {
          //          return a.text
          return a.option;
        }
      }
    },
    progressValue(index) {
      let visited = this.show_results || index <= this.current_question;
      return {
        bad: visited && this.scores[index] == 0,
        bof:
          visited &&
          this.quiz.questions &&
          this.scores[index] > 0 &&
          this.scores[index] < this.quiz.questions[index].options.length - 1,
        good:
          visited &&
          this.quiz.questions &&
          this.scores[index] == this.quiz.questions[index].options.length - 1,
        current: index == this.current_question
      };
    }
  },
  provide: function() {
    return {
      nextQuestion: this.nextQuestion,
      gotoResults: this.gotoRestults
    };
  },
  computed: {
    quizProgressResults() {
      return {
        qprogress: !this.onResultsPage,
        qresults: this.onResultsPage
      };
    },
    onResultsPage() {
      return (
        !!this.quiz.questions &&
        this.current_question == this.quiz.questions.length
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "./style/animations.scss";

#quiz {
  overflow-x: hidden;
  #help,
  #ok,
  #next {
    @extend .mt-3;
  }
}

#quiz-progress {
  transition: all 1s;

  &.qprogress {
    @extend .py-2;

    ul {
      @extend .row;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        @extend .col;
        display: inline-block;
        padding-top: 2.4em;
        font-size: 0.7em;
        font-weight: bold;
        line-height: 1em;
        color: gray;
        vertical-align: top;
        min-width: 3em;
        position: relative;
        text-align: center;
        text-overflow: ellipsis;
      }

      li:before {
        position: absolute;
        top: 0.1em;
        left: calc(50% - 1em);
        z-index: 1;
        width: 2em;
        height: 2em;
        color: white;
        border: 0.2em solid white;
        border-radius: 50%;
        line-height: 1.7em;
      }
    }

    .status-bar {
      height: 0.1em;
      background: gray;
      position: relative;
      top: 0.85em;
      margin: 0 auto;
      width: 84%;
    }
  }

  &.qresults {
    @extend .py-2;
    @extend .mt-0;
    @extend .mx-auto;

    display: inline-block;
    width: 100%;

    ul {
      padding: 0;
      list-style: none;
      @extend .mx-auto;
      width: max-content;

      li {
        padding-top: 1em;
        font-size: 1em;
        font-weight: bold;
        color: gray;
        vertical-align: top;
        min-width: 3em;
        position: relative;
        text-align: left;
        text-overflow: ellipsis;
      }

      li:before {
        position: relative;
        display: inline-block;
        text-align: center;
        /* top: 0.1em;
           left: calc(50% - 1em); */
        z-index: 1;
        width: 2em;
        height: 2em;
        color: white;
        border: 0.2em solid white;
        border-radius: 50%;
        margin-right: 1em;
      }
    }

    .status-bar {
      display: none;
    }
  }

  ul li:before {
    content: "?";
    background: gray;
  }

  ul li.good:before {
    content: "\2714";
    background: green;
  }

  ul li.bof:before {
    content: "\2714";
    background: orange;
  }

  ul li.bad:before {
    content: "x";
    background: red;
  }

  ul li.current:before {
    box-shadow: 0 0 0 0.2em mediumseagreen;
  }
}
</style>
