import { render, staticRenderFns } from "./QuizQuestion.vue?vue&type=template&id=5fec30cb&scoped=true&"
import script from "./QuizQuestion.vue?vue&type=script&lang=js&"
export * from "./QuizQuestion.vue?vue&type=script&lang=js&"
import style0 from "./QuizQuestion.vue?vue&type=style&index=0&id=5fec30cb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fec30cb",
  null
  
)

export default component.exports